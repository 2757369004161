<template>
  <div>
    <div class="router-view-container space-y-4">
      <!-- 正在使用的方案 -->
      <div class="bg-success text-white rounded-2xl w-full p-4 space-y-2 ml-auto mr-0">
        <div class="flex items-center justify-between">
          <h3 class="h3 text-white">啟用中的方案</h3>
        </div>
        <div class="">
          <p class="text-2xl font-bold">形象官網</p>
          <p class="">方案到期日 xxxx/xx/xx</p>
        </div>
        <vs-button size="large" color="warning" style="margin-left: auto; margin-right: 0">購買方案/升級方案</vs-button>
      </div>
      <!-- 訂單列表 -->
      <div v-if="is_API_loaded" class="bg-white rounded-2xl w-full">
        <section class="text-sm overflow-x-auto">
          <div class="text-gray-text_dark font-medium flex items-center px-4 h-14 w-min md:w-full">
            <div class="title_section" key="2">
              <span class="title1">訂單編號</span>
              <span class="title2">方案名稱</span>
              <span class="title4">訂單日期</span>
              <span class="title3">訂單狀態</span>
              <span class="title5">訂單金額</span>
              <span class="title6"></span>
            </div>
          </div>

          <div class="flex flex-col divide-y divide-gray-background text-gray-text_light w-min md:w-full">
            <div @click="openDialog(item)" v-for="item in records" :key="item.seono">
              <div class="flex items-center h-14 hover:bg-gray-background transition-colors cursor-pointer px-4 relative group">
                <div class="title_section">
                  <span class="title1">{{ item.ordernum }}</span>
                  <span class="title2">{{ item.pname }}</span>
                  <span class="title4">{{ item.odate }}</span>
                  <div class="title3">
                    <span
                      class="rounded-full px-3 py-1"
                      :class="[
                        item.ostatus === -3 ? ostatusStyle.ostatus_3 : '',
                        item.ostatus === -2 ? ostatusStyle.ostatus_2 : '',
                        item.ostatus === -1 ? ostatusStyle.ostatus_1 : '',
                        item.ostatus === 0 ? ostatusStyle.ostatus0 : '',
                        item.ostatus === 1 ? ostatusStyle.ostatus1 : '',
                        item.ostatus === 2 ? ostatusStyle.ostatus2 : '',
                      ]"
                      >{{ ostatusFunction(item.ostatus) }}</span
                    >
                  </div>
                  <span class="title5">NT${{ item.opays }}</span>
                  <div class="title6 flex justify-end"><vs-button transparent color="success">查看詳情</vs-button></div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <!-- pagination -->
      <div v-if="pagination.tpages > 1" class="flex flex-col items-center space-y-2">
        <vs-pagination @click.native="changePagination" not-arrows v-model="currentPage" :length="pagination.tpages" />
        <span class="text-xs text-gray-400">總共 {{ pagination.pcounts }} 筆</span>
      </div>
    </div>

    <!-- 訂單詳情 dialog -->
    <vs-dialog overflow-hidden blur scroll v-model="dialog" not-center>
      <template #header>
        <div class="flex items-center justify-between">
          <h1 class="h3">訂單詳情</h1>
          <h3
            class="rounded-full px-3 py-1 text-sm"
            :class="[
              record_details.ostatus === -3 ? ostatusStyle.ostatus_3 : '',
              record_details.ostatus === -2 ? ostatusStyle.ostatus_2 : '',
              record_details.ostatus === -1 ? ostatusStyle.ostatus_1 : '',
              record_details.ostatus === 0 ? ostatusStyle.ostatus0 : '',
              record_details.ostatus === 1 ? ostatusStyle.ostatus1 : '',
              record_details.ostatus === 2 ? ostatusStyle.ostatus2 : '',
            ]"
          >
            {{ ostatusFunction(record_details.ostatus) }}
          </h3>
        </div>
      </template>
      <!-- dialog內容 -->
      <section>
        <div class="dialog_table" v-for="(item, index) in _recordAry" :key="index">
          <div class="dialog_table_title">
            <span>{{ item.title }}</span>
          </div>
          <div class="dialog_table_value">
            <span>{{ item.value }}</span>
          </div>
        </div>
        <!-- 需判斷的欄位 -->
        <div v-if="record_details.pstatus === 1" class="dialog_table">
          <div class="dialog_table_title">
            <span>付款時間</span>
          </div>
          <div class="dialog_table_value">
            <span>{{ record_details.pdate }}</span>
          </div>
        </div>
        <div v-if="record_details.pstatus === 1 && record_details.pterms !== 0" class="dialog_table">
          <div class="dialog_table_title">
            <span>付款方式</span>
          </div>
          <div class="dialog_table_value">
            <span>{{ ptermsFunction(record_details.pterms) }}</span>
          </div>
        </div>
        <div v-if="record_details.pstatus === 1 && record_details.pterms !== 0" class="flex justify-center mt-4">
          <vs-button size="large" color="danger">重新付款</vs-button>
        </div>
      </section>
    </vs-dialog>
  </div>
</template>

<script>
export default {
  name: 'records',
  data() {
    return {
      is_API_loaded: false,
      record_details: {},
      records: [],
      pagination: {},
      currentPage: 1,
      dialog: false,
      ostatusStyle: {
        ostatus_3: 'bg-danger text-white',
        ostatus_2: 'bg-gray-background text-gray-text_light',
        ostatus_1: 'bg-warning text-white',
        ostatus0: 'bg-success text-white',
        ostatus1: 'bg-warning text-white',
        ostatus2: 'bg-gray-background text-gray-text_light',
      },
    }
  },
  computed: {
    _recordAry() {
      return [
        {
          title: '訂單編號',
          value: this.record_details.ordernum,
        },
        {
          title: '訂單日期',
          value: this.record_details.odate,
        },
        {
          title: '訂單金額',
          value: 'NT$' + this.record_details.opays,
        },
        {
          title: '方案名稱',
          value: this.record_details.pname,
        },
        {
          title: '方案月數',
          value: this.record_details.mnum + '個月',
        },
        {
          title: '方案開始',
          value: this.record_details.use_vdate,
        },
        {
          title: '方案到期',
          value: this.record_details.use_edate,
        },
      ]
    },
  },
  created() {
    this.getRecordsData(1)
  },
  methods: {
    // 4.2.3.1 讀消費紀錄列表
    getRecordsData(pageVal) {
      this.$axios({
        url: 'front/set/basic/getSOrderList.php',
        headers: {
          stoken: this.$store.state.login.userInfo.stoken,
        },
        kcval: true,
        data: {
          lang: 0,
          page: pageVal,
          pstatus: -99, // 付款狀態(-99全 -1已取消, 0未付款,1已付款,2退費完成)
        },
      }).then((res) => {
        if (res.data.Success) {
          this.records = res.data.Data
          this.pagination = res.data.Page
          this.is_API_loaded = true
        } else {
          this.$myVS.openNoti('發生錯誤，請稍候再試', res.data.Message, 'danger', 10000)
        }
      })
    },
    openDialog(item) {
      this.record_details = item
      this.dialog = true
    },
    // 改變頁籤時觸發
    changePagination() {
      this.getRecordsData(this.currentPage)
    },
    ostatusFunction(ostatus) {
      if (ostatus === -3) return '交易異常'
      if (ostatus === -2) return '訂單取消'
      if (ostatus === -1) return '申請取消'
      if (ostatus === 0) return '訂單成立'
      if (ostatus === 1) return '申請退費'
      if (ostatus === 2) return '退費完成'
    },
    ptermsFunction(pterms) {
      if (pterms === 1) return '線上刷卡'
      if (pterms === 2) return 'ATM轉帳'
      if (pterms === 3) return 'WebATM'
      if (pterms === 4) return '超商代碼'
      if (pterms === 5) return '超商條碼'
      if (pterms === 6) return '匯款公司'
    },
  },
}
</script>

<style lang="scss" scoped>
.title_section {
  @apply flex items-center w-min md:w-full;
}

.title1 {
  width: 200px;
}
.title2 {
  width: 200px;
}
.title3 {
  width: 200px;
}
.title4 {
  width: 200px;
}
.title5 {
  width: 100px;
}

.title6 {
  width: 100px;
}

.dialog_table {
  @apply flex items-center py-2;
}

.dialog_table_title {
  @apply text-sm pr-2 h-full font-medium;
}

.dialog_table_value {
  @apply text-sm pl-2 h-full;
}
</style>
