var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"router-view-container space-y-4"},[_c('div',{staticClass:"bg-success text-white rounded-2xl w-full p-4 space-y-2 ml-auto mr-0"},[_vm._m(0),_vm._m(1),_c('vs-button',{staticStyle:{"margin-left":"auto","margin-right":"0"},attrs:{"size":"large","color":"warning"}},[_vm._v("購買方案/升級方案")])],1),(_vm.is_API_loaded)?_c('div',{staticClass:"bg-white rounded-2xl w-full"},[_c('section',{staticClass:"text-sm overflow-x-auto"},[_c('div',{staticClass:"text-gray-text_dark font-medium flex items-center px-4 h-14 w-min md:w-full"},[_c('div',{key:"2",staticClass:"title_section"},[_c('span',{staticClass:"title1"},[_vm._v("訂單編號")]),_c('span',{staticClass:"title2"},[_vm._v("方案名稱")]),_c('span',{staticClass:"title4"},[_vm._v("訂單日期")]),_c('span',{staticClass:"title3"},[_vm._v("訂單狀態")]),_c('span',{staticClass:"title5"},[_vm._v("訂單金額")]),_c('span',{staticClass:"title6"})])]),_c('div',{staticClass:"flex flex-col divide-y divide-gray-background text-gray-text_light w-min md:w-full"},_vm._l((_vm.records),function(item){return _c('div',{key:item.seono,on:{"click":function($event){return _vm.openDialog(item)}}},[_c('div',{staticClass:"flex items-center h-14 hover:bg-gray-background transition-colors cursor-pointer px-4 relative group"},[_c('div',{staticClass:"title_section"},[_c('span',{staticClass:"title1"},[_vm._v(_vm._s(item.ordernum))]),_c('span',{staticClass:"title2"},[_vm._v(_vm._s(item.pname))]),_c('span',{staticClass:"title4"},[_vm._v(_vm._s(item.odate))]),_c('div',{staticClass:"title3"},[_c('span',{staticClass:"rounded-full px-3 py-1",class:[
                      item.ostatus === -3 ? _vm.ostatusStyle.ostatus_3 : '',
                      item.ostatus === -2 ? _vm.ostatusStyle.ostatus_2 : '',
                      item.ostatus === -1 ? _vm.ostatusStyle.ostatus_1 : '',
                      item.ostatus === 0 ? _vm.ostatusStyle.ostatus0 : '',
                      item.ostatus === 1 ? _vm.ostatusStyle.ostatus1 : '',
                      item.ostatus === 2 ? _vm.ostatusStyle.ostatus2 : '' ]},[_vm._v(_vm._s(_vm.ostatusFunction(item.ostatus)))])]),_c('span',{staticClass:"title5"},[_vm._v("NT$"+_vm._s(item.opays))]),_c('div',{staticClass:"title6 flex justify-end"},[_c('vs-button',{attrs:{"transparent":"","color":"success"}},[_vm._v("查看詳情")])],1)])])])}),0)])]):_vm._e(),(_vm.pagination.tpages > 1)?_c('div',{staticClass:"flex flex-col items-center space-y-2"},[_c('vs-pagination',{attrs:{"not-arrows":"","length":_vm.pagination.tpages},nativeOn:{"click":function($event){return _vm.changePagination.apply(null, arguments)}},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}}),_c('span',{staticClass:"text-xs text-gray-400"},[_vm._v("總共 "+_vm._s(_vm.pagination.pcounts)+" 筆")])],1):_vm._e()]),_c('vs-dialog',{attrs:{"overflow-hidden":"","blur":"","scroll":"","not-center":""},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('div',{staticClass:"flex items-center justify-between"},[_c('h1',{staticClass:"h3"},[_vm._v("訂單詳情")]),_c('h3',{staticClass:"rounded-full px-3 py-1 text-sm",class:[
            _vm.record_details.ostatus === -3 ? _vm.ostatusStyle.ostatus_3 : '',
            _vm.record_details.ostatus === -2 ? _vm.ostatusStyle.ostatus_2 : '',
            _vm.record_details.ostatus === -1 ? _vm.ostatusStyle.ostatus_1 : '',
            _vm.record_details.ostatus === 0 ? _vm.ostatusStyle.ostatus0 : '',
            _vm.record_details.ostatus === 1 ? _vm.ostatusStyle.ostatus1 : '',
            _vm.record_details.ostatus === 2 ? _vm.ostatusStyle.ostatus2 : '' ]},[_vm._v(" "+_vm._s(_vm.ostatusFunction(_vm.record_details.ostatus))+" ")])])]},proxy:true}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('section',[_vm._l((_vm._recordAry),function(item,index){return _c('div',{key:index,staticClass:"dialog_table"},[_c('div',{staticClass:"dialog_table_title"},[_c('span',[_vm._v(_vm._s(item.title))])]),_c('div',{staticClass:"dialog_table_value"},[_c('span',[_vm._v(_vm._s(item.value))])])])}),(_vm.record_details.pstatus === 1)?_c('div',{staticClass:"dialog_table"},[_c('div',{staticClass:"dialog_table_title"},[_c('span',[_vm._v("付款時間")])]),_c('div',{staticClass:"dialog_table_value"},[_c('span',[_vm._v(_vm._s(_vm.record_details.pdate))])])]):_vm._e(),(_vm.record_details.pstatus === 1 && _vm.record_details.pterms !== 0)?_c('div',{staticClass:"dialog_table"},[_c('div',{staticClass:"dialog_table_title"},[_c('span',[_vm._v("付款方式")])]),_c('div',{staticClass:"dialog_table_value"},[_c('span',[_vm._v(_vm._s(_vm.ptermsFunction(_vm.record_details.pterms)))])])]):_vm._e(),(_vm.record_details.pstatus === 1 && _vm.record_details.pterms !== 0)?_c('div',{staticClass:"flex justify-center mt-4"},[_c('vs-button',{attrs:{"size":"large","color":"danger"}},[_vm._v("重新付款")])],1):_vm._e()],2)])],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex items-center justify-between"},[_c('h3',{staticClass:"h3 text-white"},[_vm._v("啟用中的方案")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{},[_c('p',{staticClass:"text-2xl font-bold"},[_vm._v("形象官網")]),_c('p',{},[_vm._v("方案到期日 xxxx/xx/xx")])])}]

export { render, staticRenderFns }